<template>
    <!-- 销售关系管理 -->
    <div class="all_main_wrap">
        <HeaderBox>
            <template #title>
                销售关系管理
            </template>
            <template #input>
                <!-- <a-input v-model="queryParams.productName" placeholder="请输入商品名称" allowClear /> -->
                <a-auto-complete v-model="queryParams.productName" placeholder="输入搜索商品名称"
                    option-label-prop="value" @search="onSearchRole" :defaultActiveFirstOption="false">
                    <a-spin v-if="fetching" class="auto-spin" slot="notFoundContent" size="small" />
                    <template slot="dataSource">
                        <a-select-option @click="onSelectRole(item, 1)" v-for="(item, index) in list" :key="index + ''"
                            :value="item.productName">
                            <a-row type="flex" justify="space-between" align="middle">
                                <a-col>{{ item.productName }}</a-col>
                            </a-row>
                        </a-select-option>
                    </template>
                </a-auto-complete>
                <a-select placeholder="请选择销售关系" v-model="queryParams.relationType">
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <!-- 1-赠品 2-相关商品 3-组合商品 4-规格 5-推荐商品） -->
                    <a-select-option :value="0">全部</a-select-option>
                    <a-select-option :value="1">赠品</a-select-option>
                    <a-select-option :value="2">相关商品</a-select-option>
                    <a-select-option :value="3">组合商品</a-select-option>
                    <a-select-option :value="4">规格</a-select-option>
                    <a-select-option :value="5">系列商品</a-select-option>
                </a-select>
                <a-button type="primary" class="btn" @click="onSearch" :loading="loading">查询</a-button>
                <a-button type="primary" class="all_boder_btn" @click="showAddRelation(1)" v-hasPermi="['commodity:productRelation:add']">增加销售关系</a-button>
            </template>
        </HeaderBox>

        <a-table class="table-template" :rowKey="(item) => item.relationId" :columns="columns" :loading="loading"
            :data-source="tableData" :locale="{ emptyText: '暂无数据' }" @change="onPage" :pagination="{
                total: total,
                current: queryParams.pageNum,
                defaultpageSize: queryParams.pageSize,
                showSizeChanger: true,
                showTotal: function (total, range) {
                    return `共${total}条`
                }
            }">
            <!-- 序号 -->
            <template slot="index" slot-scope="item, row, index">
                {{ (queryParams.pageNum - 1) * queryParams.pageSize + index + 1 }}
            </template>
            <!-- 销售关系 -->
            <template slot="relationType" slot-scope="item">
                <span>{{ handleRelationType(item) }}</span>
            </template>
            <!-- 显示名称 -->
            <template slot="titleName" slot-scope="item">
              <span>{{ item ? item : '-' }}</span>
            </template>
            <!-- 购买价格 -->
            <template slot="price" slot-scope="item">
              <span>{{ item ? item.toFixed(2) : '-' }}</span>
            </template>
            <!-- 销售关系类别 -->
            <template slot="productCategory" slot-scope="item">
                <span>{{ handleRelationCategory(item) }}</span>
            </template>
            <!-- 排序 -->
            <template slot="sort" slot-scope="item, row">
                <a-input-number id="inputNumber" :disabled="loading" v-model="item" :min="1"
                    @change="onSortChange(item, row)" />
            </template>
            <!-- 操作 -->
            <template slot="operation" slot-scope="item">
                <span class="blueText">
                    <span v-hasPermi="['commodity:productRelation:edit']" @click="editData(item)">编辑</span>
                    <span v-hasPermi="['commodity:productRelation:delete']" @click="deleteData(item.relationId)"> |
                        删除</span>
                </span>
            </template>
        </a-table>

        <!-- 新建/编辑 商品销售关系弹窗 -->
        <a-modal v-model="relationVisible" title="添加销售关系" :confirm-loading="confirmLoading" @ok="onAddRelation"
            okText="确认" @cancel="handleCancel()">
            <a-row class="rows">
                <a-col span="5" class="left required">主商品</a-col>
                <a-col span="19" class="right flex">
                    <a-auto-complete style="width: 100%" v-model="relationAddForm.productName" placeholder="输入搜索商品名称"
                        option-label-prop="value" @search="onSearchRole" :defaultActiveFirstOption="false">
                        <a-spin v-if="fetching" class="auto-spin" slot="notFoundContent" size="small" />
                        <template slot="dataSource">
                            <a-select-option @click="onSelectRole(item, 1)" v-for="(item, index) in list"
                                :key="index + ''" :value="item.productName">
                                <a-row type="flex" justify="space-between" align="middle">
                                    <a-col>{{ item.productName }}</a-col>
                                </a-row>
                            </a-select-option>
                        </template>
                    </a-auto-complete>
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left required">关联商品</a-col>
                <a-col span="19" class="right flex">
                    <a-auto-complete style="width: 100%" v-model="relationAddForm.relatedProductName"
                        placeholder="输入搜索商品名称" option-label-prop="value" @search="onSearchRole"
                        :defaultActiveFirstOption="false">
                        <a-spin v-if="fetching" class="auto-spin" slot="notFoundContent" size="small" />
                        <template slot="dataSource">
                            <a-select-option @click="onSelectRole(item, 2)" v-for="(item, index) in list"
                                :key="index + ''" :value="item.productName">
                                <a-row type="flex" justify="space-between" align="middle">
                                    <a-col>{{ item.productName }}</a-col>
                                </a-row>
                            </a-select-option>
                        </template>
                    </a-auto-complete>
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left required">销售关系</a-col>
                <a-col span="19" class="right flex">
                    <a-select placeholder="请选择销售关系" style="width: 100%" v-model="relationAddForm.relationType" @change="resetData">
                        <a-icon slot="suffixIcon" type="caret-down" />
                        <!-- 1-赠品 2-相关商品 3-组合商品 4-规格 5-推荐商品） -->
                        <a-select-option :value="1">赠品</a-select-option>
                        <a-select-option :value="2">相关商品</a-select-option>
                        <a-select-option :value="3">组合商品</a-select-option>
                        <a-select-option :value="4">规格</a-select-option>
                        <a-select-option :value="5">系列商品</a-select-option>
                    </a-select>
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left">商品类别</a-col>
                <a-col span="19" class="right flex">
                    <a-radio-group v-model="relationAddForm.productCategory">
                        <a-radio :value="0">个人商品</a-radio>
                        <a-radio :value="1">机构商品</a-radio>
                    </a-radio-group>
                </a-col>
            </a-row>
            <a-row class="rows">
                <a-col span="5" class="left">显示名称</a-col>
                <a-col span="19" class="right flex">
                    <a-input v-model="relationAddForm.title" placeholder="请填写显示名称" />
                </a-col>
            </a-row>
            <template v-if="relationAddForm.relationType == 3">
            <a-row class="rows">
                <a-col span="5" class="left">购买价格</a-col>
                <a-col span="19" class="right flex">
                <a-input v-model="relationAddForm.price" placeholder="请输入购买价格" />
                </a-col>
            </a-row>
            </template>
            <!-- <a-row class="rows">
                <a-col span="5" class="left">排序</a-col>
                <a-col span="19" class="right flex">
                    <a-input v-model="relationAddForm.sort" placeholder="请填写排序权重" />
                </a-col>
            </a-row> -->
            <a-row class="rows">
                <a-col span="5" class="left">显示图片</a-col>
                <a-col span="19" class="right">
                    <a-upload name="picture" accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" list-type="picture-card"
                        :showUploadList="false" :loading="beforeUpload" :customRequest="customRequestProduct">
                        <img v-if="relationAddForm.photo" :src="relationAddForm.photo" style="max-width: 180px"
                            alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'plus'" />
                            <div class="ant-upload-text">上传图片</div>
                        </div>
                    </a-upload>
                </a-col>
            </a-row>
        </a-modal>
    </div>
</template>

<script>
import HeaderBox from "@/components/HeaderBox.vue";
const columns = [
    {
        title: "序号",
        align: "center",
        dataIndex: "index",
        scopedSlots: {
            customRender: "index",
        },
    },
    {
        title: "商品名称",
        align: "center",
        dataIndex: "productName",
    },
    {
        title: "关联商品名称",
        align: "center",
        dataIndex: "relatedProductName",
    },
    {
        title: "销售关系",
        align: "center",
        dataIndex: "relationType",
        scopedSlots: { customRender: "relationType" },
    },
    {
        title: "显示名称",
        align: "center",
        dataIndex: "title",
        scopedSlots: { customRender: "titleName" },
    },
    {
        title: "购买价格",
        align: "center",
        dataIndex: "price",
        scopedSlots: { customRender: "price" },
    },
    // {
    //     title: "显示图片",
    //     align: "center",
    //     dataIndex: "photo",
    //     scopedSlots: { customRender: "photo" },
    // },
    {
        title: "关系类别",
        align: "center",
        dataIndex: "productCategory",
        scopedSlots: { customRender: "productCategory" },
    },
    {
        title: "排序",
        align: "center",
        dataIndex: "sort",
        scopedSlots: { customRender: "sort" },
    },
    {
        title: "操作",
        align: "center",
        scopedSlots: {
            customRender: "operation",
        },
    },
];
export default {
    // 可用组件的哈希表
    components: { HeaderBox },
    // 接收传值
    props: {},
    // 数据对象
    data() {
        return {
            columns,
            loading: false,
            tableData: [],
            total: 0,
            queryParams: {
                productName: null,
                relationType: undefined, // 关系类型（1-赠品 2-相关商品 3-组合商品 4-规格 5-系列商品）
                pageNum: 1, //页码
                pageSize: 10, // 页数
            },
            // 商品销售关系配置
            relationVisible: false, // 是否显示新建/编辑销售关系弹窗
            fetching: false,
            list: [],
            productId: 0,
            confirmLoading: false,
            submitLoading: false, // 新增销售关系loading
            listData: [], // 销售关系列表
            columns, // 销售关系列表配置
            relationEditType: 0, // 1：新增 2：编辑
            // 商品参数
            productForm: {
                name: '',
                page: 1,
                size: 999,
                noExam: 0 // 是否查询模拟考试商品 1查 0 不查
            },
            // 新增销售关系参数
            relationAddForm: {
                photo: null,
                price: null,
                productCategory: 0, // 商品类别（0：个人商品 1：机构商品）	
                productId: null,
                relatedProductId: null, // 关联商品ID
                relationType: undefined, // 关系类型（1-赠品 2-相关商品 3-组合商品 4-规格 5-系列商品）
                sort: 1,
                title: null // 显示名称
            },
        }
    },
    // 事件处理器
    methods: {
        // 分页
        onPage(e) {
            this.queryParams.pageNum = e.current
            this.queryParams.pageSize = e.pageSize
            this.getList()
        },

        onSearch() {
            this.queryParams.pageNum = 1;
            this.getList();
        },

        // 获取商品关系接口（分页）
        getList(e) {
            if (this.queryParams.relationType == 0) {
                this.queryParams.relationType = null;
            }
            this.loading = true
            this.$ajax({
                url: "/hxclass-management/productRelation/list/page",
                params: this.queryParams,
            }).then((res) => {
                this.loading = false
                if (res.code == 200 && res.success) {
                    this.total = res.data.total;
                    this.tableData = res.data.records;
                }
            });
        },

        // 删除销售关系
        deleteData(id) {
            let _this = this;
            this.$confirm({
                title: "是否确认删除销售关系?",
                okText: "确认",
                onOk() {
                    _this
                        .$ajax({
                            url: "/hxclass-management/productRelation/" + id,
                            method: "DELETE",
                        })
                        .then((res) => {
                            if (res.code == 200 && res.success) {
                                _this.$message.success("删除成功");
                                _this.getList();
                            }
                        });
                },
                onCancel() { },
            });
        },
        // 编辑销售关系
        editData(item) {
            this.relationAddForm = JSON.parse(JSON.stringify(item));
            this.showAddRelation(2);
        },
        // 展示新增销售关系弹窗
        showAddRelation(type) {
            this.relationEditType = type;
            this.relationVisible = true;
        },
        // 增加/修改销售关系
        onAddRelation() {
            if (!this.relationAddForm.productId) {
                this.$message.warning("请选择主商品");
                return;
            }
            if (!this.relationAddForm.relatedProductId) {
                this.$message.warning("请选择关联商品");
                return;
            }
            if (!this.relationAddForm.relationType) {
                this.$message.warning("请选择销售关系类型");
                return;
            }
            this.relationAddForm.price = this.relationAddForm.price ? Number(this.relationAddForm.price):null;
            // console.log("=========" + JSON.stringify(this.relationAddForm));
            // return;
            this.submitLoading = true;
            this.$ajax({
                url: '/hxclass-management/productRelation',
                method: this.relationEditType == 1 ? 'post' : 'put',
                params: this.relationAddForm,
            }).then(res => {
                if (res.code == 200 && res.success) {
                    this.$message.success(this.relationEditType == 1 ? "新增销售关系成功" : "修改销售关系成功");
                    this.handleCancel()
                    this.relationVisible = false
                    this.getList()
                } else {
                    this.$message.error(res.message)
                }
                this.submitLoading = false
            })

        },
        // 修改排序
        onSortChange(e, item) {
            if (!e) {
                return;
            }
            this.loading = true;
            if (this.timer) {
                clearTimeout(this.timer)
            }

            this.timer = setTimeout(() => {
                this.timer = null;
                this.$ajax({
                    url: '/hxclass-management/productRelation',
                    method: 'put',
                    params: {
                        relationId: item.relationId,
                        sort: e,
                    },
                }).then(res => {
                    this.loading = false
                    if (res.code == 200 && res.success) {
                        this.$message.success("修改成功");
                        this.getList()
                    } else {
                        this.$message.error(res.message)
                    }
                })
            }, 600)

        },
        // 重置销售关系数据
        resetData() {
            this.relationAddForm.price = null;
        },
        // 弹窗关闭重置信息
        handleCancel() {
            this.relationAddForm.relatedProductId = null;
            this.relationAddForm.relatedProductName = null;
            this.relationAddForm.photo = null;
            this.relationAddForm.price = null;
            this.relationAddForm.productCategory = 0;
            this.relationAddForm.productId = null;
            this.relationAddForm.productName = null;
            this.relationAddForm.relationType = undefined;
            this.relationAddForm.sort = 1;
            this.relationAddForm.title = null
            this.relationEditType = 0;
        },
        // 检索商品
        onSearchRole(val) {
            this.fetching = false;
            this.list = [];
            if (!val) {
                clearTimeout(this.tiemID);
                return;
            }
            this.productForm.name = val
            this.$nextTick(function () {
                if (val !== "") {
                    this.fetching = true;
                    const _this = this;
                    clearTimeout(this.tiemID);
                    this.tiemID = setTimeout(function () {
                        _this
                            .$ajax({
                                method: "get",
                                url: "/hxclass-management/product-archives/manage/select-win",
                                params: _this.productForm,
                            })
                            .then((res) => {
                                if (res.code == 200 && res.success) {
                                    _this.list = res.data.records;
                                    _this.fetching = false;
                                }
                            });
                    }, 400);
                }
            });
        },
        // 打印选中商品
        onSelectRole(e, type) {
            if (type == 1) {// 主商品
                this.relationAddForm.productId = e.productId;
                this.relationAddForm.productName = e.productName;
            } else {// 关联商品
                this.relationAddForm.relatedProductId = e.productId;
            }

        },
        // 上传前钩子
        beforeUpload(file) {
            if (!this.$regular.imgReg.reg.test(file.name)) {
                this.$message.warning(this.$regular.imgReg.msg);
                return false;
            }
        },

        // 文件上传
        customRequestProduct(fileData) {
            this.loading = true;

            const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
            // 腾讯云对象存储上传文件
            this.$cosUpload(fileData.file, bucket, this.fileProgressCallback, (url, fileName) => {
                // 上传完成
                if (url) {
                    this.relationAddForm.photo = url
                } else {
                    this.$message.error("上传失败");
                }
                this.loading = false;
            });
        },
        // 处理销售关系名称
        handleRelationType(type) {
            return type == 1 ? "赠品" : type == 2 ? "相关商品" : type == 3 ? "组合商品" : type == 4 ? "规格" : type == 5 ? "系列商品" : "全部"
        },
        // 处理销售关系类别名称
        handleRelationCategory(type) {
            return type == 1 ? "机构商品" : "个人商品"
        },

    },
    // 生命周期-实例创建完成后调用
    created() {
        this.getList()
    },
    // 生命周期-实例挂载后调用
    mounted() { },
    // 生命周期-实例销毁离开后调用
    destroyed() { },
    // 计算属性监听
    computed: {},
    // 自定义的侦听器
    watch: {}
}
</script>

<style lang="less" scoped>
@import "../curriculum/NewCourseDetails/style.less";

.right_box .input {
    width: 100%;
}
.btn {
    margin: 0 24px 14px 0;
}

.pictrue {
    width: 120px;
}

.required::before {
    content: '*';
    color: red;
    margin-right: 2px;
}
</style>